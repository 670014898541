/** @format */

const projectsData = [
  {
    title: "Defi Application",
    githubLink: "https://github.com/saakshiraut28/solana-defi-app",
    livePreview: "https://solana-defi-app.vercel.app/",
    description:
      "An application that provides robust features for wallet connectivity, token swapping, and real-time visualization of cryptocurrency prices",
    tech: "#Solana #Web3.js",
    category: "web3",
  },
  {
    title: "Solana Counter",
    githubLink: "https://github.com/saakshiraut28/solana_counter",
    livePreview: "https://solana-counter-sand.vercel.app/",
    description:
      "A counter that you can increment by signing in using your wallet.",
    tech: "#Solana #Web3.js",
    category: "web3",
  },
  {
    title: "Get Together",
    githubLink: "https://github.com/MayankBansal12/Getogether",
    livePreview: "https://getogether-ten.vercel.app/",
    description:
      "Getogether is a platform to help you manage your event in a better and more organised way! Categorizing your whole events into multiple sub-events and creating channels for that and groups to manage conversation among all event participants.",
    tech: "#ReactJS #Nodejs #Tailwind #Typescript #Prisma",
    category: "web2",
  },
  {
    title: "Service Hub",
    githubLink: "https://github.com/saakshiraut28/Service_Hub",
    livePreview: "#",
    description:
      "This was my mini project. We created an amazing platform to bridge the gap between migrants and service providers.",
    tech: "#ReactJS #Tailwind #MongoDB #ExpressJS #NodeJS #MERN",
    category: "web2",
  },
];

export default projectsData;
